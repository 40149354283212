<template>
  <div class=" xxl:grid xxl:grid-cols-2 xxl:gap-10 mt-10 px-10">
    <div class="ad-slider mb-5 xxl:mb-0">
      <TinySlider
        :options="{
          mode: 'gallery',
          controls: true,
          nav: true,
          speed: 500
        }"
      >
        <div class="dashboard-ad-img" v-for="item in sliders" :key="item.id">
          <div class="w-full rounded-md overflow-hidden">
            <a :href="item.href" :target="item.target">
              <img
                alt="CISA"
                class=" max-w-full h-auto"
                :src="require(`@/assets/images/sliders/${item.src}`)"
            /></a>
          </div>
        </div>
        <!-- <div class="dashboard-ad-img">
          <div class="w-full rounded-md overflow-hidden">
            <img
              alt="CISA"
              class=" max-w-full h-auto"
              :src="require(`@/assets/images/1200x500.png`)"
            />
          </div>
        </div>
        <div class="dashboard-ad-img">
          <div class="w-full rounded-md overflow-hidden">
            <img
              alt="CISA"
              class=" max-w-full h-auto"
              :src="require(`@/assets/images/1200x500.png`)"
            />
          </div>
        </div> -->
      </TinySlider>
    </div>
    <div class="">
      <div class="box p-5 h-full">
        <h3 class=" text-xl font-bold">公告與系統通知</h3>
        <div class="mt-3">
          <!-- <div class="flex h-52 items-center justify-center text-gray-600 text-lg">
            目前沒有最新公告
            <MessageCircleIcon class="h-6 w-6" />
          </div> -->
          <ol>
            <li
              v-for="info in news"
              :key="info.id"
              class="mb-3 cursor-pointer "
              @click="showNews(info.id)"
            >
              <div class=" flex items-center">
                <a
                  class="message-class w-16 bg-theme-11 text-gray-900 text-center mr-3 rounded-3xl"
                  :href="info.classHref"
                  >{{ info.type }}</a
                >
                <span class=" w-full text-gray-600">{{
                  getTime(info.date)
                }}</span>
              </div>
              <div class="py-3">
                <a
                  class=" text-base font-medium py-3 hover:text-theme-1"
                  :href="info.href"
                  target="_blank"
                >
                  {{ info.title }}
                </a>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div
    class="xxl:flex justify-between items-baseline mt-10 p-10 border-t border-theme-5"
  >
    <div class="grid grid-cols-2 gap-5 xxl:mr-10 w-full xxl:w-1/2 mb-5">
      <!-- <a
        href="inquiry-mailbox"
        class="col-span-2 box p-8 zoom-in flex justify-between items-center"
        v-if="auth.isBuyer || auth.isVendor"
      >
        <div class=" flex items-center">
          <MailIcon class="w-8 h-8 mr-4" />
          <h3 class=" text-lg xxl:text-xl font-bold">業務與媒合洽詢</h3>
        </div>
        <div class="text-theme-1">共5場</div>
      </a>
      <a
        href="meeting"
        class="col-span-2 box p-8 zoom-in flex justify-between items-center"
        v-if="auth.isBuyer || auth.isVendor"
      >
        <div class=" flex items-center">
          <CalendarIcon class="w-8 h-8 mr-4" />
          <h3 class=" text-lg xxl:text-xl font-bold">線上會議預定</h3>
        </div>
        <div class="text-theme-1">共5場</div>
      </a> -->
      <a
        href="user"
        class="col-span-2 lg:col-span-1 box p-8 bg-theme-1 zoom-in flex items-center"
      >
        <UserIcon class="w-8 h-8 mr-4 text-white" />
        <h3 class=" text-lg xxl:text-xl font-bold text-white">會員個人資料</h3>
      </a>
    </div>

    <div class="grid grid-cols-2 gap-5 w-full xxl:w-1/2">
      <a
        href="company"
        class="col-span-2 lg:col-span-1 box p-8 bg-theme-1 zoom-in flex items-center"
        v-if="auth.isVendor"
      >
        <BriefcaseIcon class="w-8 h-8 mr-4 text-white" />
        <h3 class=" text-lg xxl:text-xl font-bold text-white">
          供應商企業資料
        </h3>
      </a>
      <a
        href="member"
        class="col-span-2 lg:col-span-1 box p-8 bg-theme-1 zoom-in flex items-center"
        v-if="auth.isVendor"
      >
        <UsersIcon class="w-8 h-8 mr-4 text-white" />
        <h3 class=" text-lg xxl:text-xl font-bold text-white">企業成員管理</h3>
      </a>
      <a
        href="product"
        class="col-span-2 lg:col-span-1 box p-8 bg-theme-1 zoom-in flex items-center"
        v-if="auth.isVendor"
      >
        <BoxIcon class="w-8 h-8 mr-4 text-white" />
        <h3 class=" text-lg xxl:text-xl font-bold text-white">產品分類管理</h3>
      </a>
      <a
        href="product"
        class="col-span-2 lg:col-span-1 box p-8 bg-theme-1 cursor-pointer zoom-in flex items-center"
        v-if="auth.isVendor"
      >
        <BoxIcon class="w-8 h-8 mr-4 text-white" />
        <h3 class=" text-lg xxl:text-xl font-bold text-white">
          產品與服務管理
        </h3>
      </a>
    </div>
  </div>
  <div>
    <!-- <template v-if="$user.Id">
  </template>
  <template v-else>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 mt-8">
          <div class="intro-y flex items-center h-10">
            <div class="font-medium">當前使用者：{{ $user ? $user.Name : '' }}</div>
          </div>
      </div>
    </div>
  </template> -->
    <detail-modal
      :modalVisible="modalVisible"
      :id="newsId"
      @dismiss="modalVisible = false"
    ></detail-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, ref, provide, onMounted, reactive } from "vue";
import CloudFun, { Model } from "@cloudfun/core";
import PieChart from "@/components/pie-chart/Main.vue";
import LineChart from "@/components/line-chart/Main.vue";
import TinySlider from "@/global-components/tiny-slider/Main.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "@/router";
import DetailModal from "../announcement-detail/Main.vue";
import formatDate from "xe-utils/toDateString";
const messageList = [
  {
    id: 1,
    class: "公告",
    classHref: "#",
    date: "2022/06/16",
    title: "數位服務媒合平台簡易操作手冊",
    href:
      "https://drive.google.com/file/d/1hRrymFfF4lDaGVaVK2dnkjSBJJYdgunm/view"
  }
  // {
  //   id: 2,
  //   class: "分類",
  //   classHref: "#",
  //   date: "2022/04/11",
  //   title: "七月「數位轉型．萬物聯網」智慧資訊科技廠商媒合展招商中",
  //   href: "announcement-detail"
  // },
  // {
  //   id: 3,
  //   class: "分類",
  //   classHref: "#",
  //   date: "2022/04/11",
  //   title: "七月「數位轉型．萬物聯網」智慧資訊科技廠商媒合展招商中",
  //   href: "announcement-detail"
  // }
];

const getTime = (value: Date) => {
  return formatDate(value, "yyyy-MM-dd hh:mm");
};

const sliders = [
  {
    id: 1,
    src: "01.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum01.html"
  },
  {
    id: 2,
    src: "02.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum02.html"
  },
  {
    id: 3,
    src: "03.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum03.html"
  },
  {
    id: 4,
    src: "04.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum04.html"
  },
  {
    id: 5,
    src: "05.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum05.html"
  },
  {
    id: 6,
    src: "06.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum06.html"
  },
  {
    id: 7,
    src: "07.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum07.html"
  },
  {
    id: 8,
    src: "08.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum08.html"
  },
  {
    id: 9,
    src: "09.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum09.html"
  },
  {
    id: 10,
    src: "10.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum10.html"
  },
  {
    id: 11,
    src: "11.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum11.html"
  },
  {
    id: 12,
    src: "12.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum12.html"
  },
  {
    id: 13,
    src: "13.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum13.html"
  },
  {
    id: 14,
    src: "14.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum14.html"
  }
];

export default defineComponent({
  components: {
    PieChart,
    LineChart,
    // Loading,
    TinySlider,
    DetailModal
  },
  setup() {
    // if (!CloudFun.user.Id) router.push('/login');
    const salesReportFilter = ref("");
    const model = CloudFun.current?.model;
    const newsId = ref();
    const modalVisible = ref(false);
    const news = ref([]);
    var load = reactive({
      isLoading: true,
      fullPage: false
    });
    var gaData = reactive<any>({});

    var pieChart = reactive({
      width: 0,
      height: 0,
      data: {
        labels: ["新訪客率", "舊訪客率"],
        datasets: [
          {
            data: [50, 50],
            backgroundColor: ["#26a1ff", "#ff2626"],
            hoverBackgroundColor: ["#26a1ff", "#ff2626"],
            borderWidth: 5,
            borderColor: cash("html").hasClass("dark") ? "#303953" : "#fff"
          }
        ]
      }
    });

    var lineChart = reactive({
      width: 0,
      height: 0,
      data: {
        labels: [],
        datasets: [
          {
            label: "人數",
            data: [],
            borderWidth: 2,
            borderColor: "#3160D8",
            backgroundColor: "transparent",
            pointBorderColor: "transparent"
          }
        ]
      }
    });

    const importantNotesRef = ref<HTMLElement>();

    provide("bind[importantNotesRef]", (el: HTMLElement) => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("next");
    };
    const auth = ref<any>({});
    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;
    const getGoogleAnalytics = () => {
      return new Promise((resolve, reject) => {
        Model.createHttpClient(baseUrl)
          .get(`${baseUrl}/api/System/GoogleAnalytics`, {})
          .then(function(response: any) {
            resolve(response.payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then((response: any) => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch((error: any) => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const loadAuthInfo = async () => {
      model
        ?.dispatch("user/auth")
        .then((response: any) => {
          if (response.status) {
            auth.value = response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch((error: any) => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const loadNews = async () => {
      model?.dispatch("news/query").then(
        payload => {
          if (payload && payload.data) {
            news.value = payload.data.slice(0, 5);
          }

          console.log("🚀 ~ file: Main.vue ~ line 320 ~ loadNews ~ news", news);
        },
        reason => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: reason
          });
        }
      );
    };

    onMounted(() => {
      loadAuthInfo();
      loadNews();
    });

    // onMounted(() => {
    //   if(CloudFun.user.Id){
    //     load.isLoading = true;
    //     getGoogleAnalytics().then((data: any) =>{
    //       Object.assign(gaData, data)
    //       gaData.TotalCountryUsers = data.UsersByCountry.map((e: any) => e.Value).reduce((a: number ,b: number) => a + b);
    //       gaData.TotalLanguageUsers = data.UsersByLanguage.map((e: any) => e.Value).reduce((a: number ,b: number) => a + b);
    //       pieChart.data.labels = ["新訪客率", "舊訪客率"];
    //       pieChart.data.datasets[0].data = [data.PercentNewSessions, 100-data.PercentNewSessions];
    //       lineChart.data.labels = data.UsersByDate.map((e: any) => { return e.Name });
    //       lineChart.data.datasets[0].data = data.UsersByDate.map((e: any)=> { return e.Value });
    //     }).catch((error) => CloudFun.send('error', { subject: 'GA資料取得失敗', content: error.message })
    //     ).finally(() => {
    //       load.isLoading = false
    //     });
    //   }
    // })

    return {
      gaData,
      pieChart,
      lineChart,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      load,
      messageList,
      auth,
      news,
      modalVisible,
      newsId,
      sliders,
      getTime
    };
  },
  methods: {
    showNews(id: number) {
      this.newsId = id;
      this.modalVisible = true;
    }
  }
});
</script>
