
import CloudFun, { computed, defineComponent, ref } from "@cloudfun/core";
import { toRef, watch } from "vue-demi";

export default defineComponent({
  components: {
    // Grid,
    // CheckBoxList
  },
  props: {
    modalVisible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number
    }
  },
  setup(props, { emit }) {
    // const visible = toRef(props, "modalVisible");
    const data = ref({} as any);
    const model = CloudFun.current?.model;

    const loadNews = async (id: number) => {
      data.value = await model?.dispatch("news/find", id);
      console.log(
        "🚀 ~ file: Main.vue ~ line 84 ~ loadNews ~ value",
        data.value
      );
    };

    const visible = computed<boolean>({
      get() {
        return props.modalVisible;
      },
      set(visible) {
        emit("update:modalVisible", visible);
      }
    });

    watch(
      () => props.id,
      current => {
        console.log("🚀 ~ file: Main.vue ~ line 90 ~ setup ~ current", current);

        if (current) loadNews(current);
      }
    );

    return {
      visible,
      data
    };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    }
  }
});
