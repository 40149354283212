
/* eslint-disable */
import { defineComponent, ref, provide, onMounted, reactive } from "vue";
import CloudFun, { Model } from "@cloudfun/core";
import PieChart from "@/components/pie-chart/Main.vue";
import LineChart from "@/components/line-chart/Main.vue";
import TinySlider from "@/global-components/tiny-slider/Main.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "@/router";
import DetailModal from "../announcement-detail/Main.vue";
import formatDate from "xe-utils/toDateString";
const messageList = [
  {
    id: 1,
    class: "公告",
    classHref: "#",
    date: "2022/06/16",
    title: "數位服務媒合平台簡易操作手冊",
    href:
      "https://drive.google.com/file/d/1hRrymFfF4lDaGVaVK2dnkjSBJJYdgunm/view"
  }
  // {
  //   id: 2,
  //   class: "分類",
  //   classHref: "#",
  //   date: "2022/04/11",
  //   title: "七月「數位轉型．萬物聯網」智慧資訊科技廠商媒合展招商中",
  //   href: "announcement-detail"
  // },
  // {
  //   id: 3,
  //   class: "分類",
  //   classHref: "#",
  //   date: "2022/04/11",
  //   title: "七月「數位轉型．萬物聯網」智慧資訊科技廠商媒合展招商中",
  //   href: "announcement-detail"
  // }
];

const getTime = (value: Date) => {
  return formatDate(value, "yyyy-MM-dd hh:mm");
};

const sliders = [
  {
    id: 1,
    src: "01.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum01.html"
  },
  {
    id: 2,
    src: "02.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum02.html"
  },
  {
    id: 3,
    src: "03.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum03.html"
  },
  {
    id: 4,
    src: "04.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum04.html"
  },
  {
    id: 5,
    src: "05.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum05.html"
  },
  {
    id: 6,
    src: "06.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum06.html"
  },
  {
    id: 7,
    src: "07.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum07.html"
  },
  {
    id: 8,
    src: "08.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum08.html"
  },
  {
    id: 9,
    src: "09.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum09.html"
  },
  {
    id: 10,
    src: "10.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum10.html"
  },
  {
    id: 11,
    src: "11.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum11.html"
  },
  {
    id: 12,
    src: "12.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum12.html"
  },
  {
    id: 13,
    src: "13.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum13.html"
  },
  {
    id: 14,
    src: "14.jpg",
    target: "_new",
    href: "https://digi.cisa.tw/forum14.html"
  }
];

export default defineComponent({
  components: {
    PieChart,
    LineChart,
    // Loading,
    TinySlider,
    DetailModal
  },
  setup() {
    // if (!CloudFun.user.Id) router.push('/login');
    const salesReportFilter = ref("");
    const model = CloudFun.current?.model;
    const newsId = ref();
    const modalVisible = ref(false);
    const news = ref([]);
    var load = reactive({
      isLoading: true,
      fullPage: false
    });
    var gaData = reactive<any>({});

    var pieChart = reactive({
      width: 0,
      height: 0,
      data: {
        labels: ["新訪客率", "舊訪客率"],
        datasets: [
          {
            data: [50, 50],
            backgroundColor: ["#26a1ff", "#ff2626"],
            hoverBackgroundColor: ["#26a1ff", "#ff2626"],
            borderWidth: 5,
            borderColor: cash("html").hasClass("dark") ? "#303953" : "#fff"
          }
        ]
      }
    });

    var lineChart = reactive({
      width: 0,
      height: 0,
      data: {
        labels: [],
        datasets: [
          {
            label: "人數",
            data: [],
            borderWidth: 2,
            borderColor: "#3160D8",
            backgroundColor: "transparent",
            pointBorderColor: "transparent"
          }
        ]
      }
    });

    const importantNotesRef = ref<HTMLElement>();

    provide("bind[importantNotesRef]", (el: HTMLElement) => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("next");
    };
    const auth = ref<any>({});
    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;
    const getGoogleAnalytics = () => {
      return new Promise((resolve, reject) => {
        Model.createHttpClient(baseUrl)
          .get(`${baseUrl}/api/System/GoogleAnalytics`, {})
          .then(function(response: any) {
            resolve(response.payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then((response: any) => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch((error: any) => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const loadAuthInfo = async () => {
      model
        ?.dispatch("user/auth")
        .then((response: any) => {
          if (response.status) {
            auth.value = response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch((error: any) => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const loadNews = async () => {
      model?.dispatch("news/query").then(
        payload => {
          if (payload && payload.data) {
            news.value = payload.data.slice(0, 5);
          }

          console.log("🚀 ~ file: Main.vue ~ line 320 ~ loadNews ~ news", news);
        },
        reason => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: reason
          });
        }
      );
    };

    onMounted(() => {
      loadAuthInfo();
      loadNews();
    });

    // onMounted(() => {
    //   if(CloudFun.user.Id){
    //     load.isLoading = true;
    //     getGoogleAnalytics().then((data: any) =>{
    //       Object.assign(gaData, data)
    //       gaData.TotalCountryUsers = data.UsersByCountry.map((e: any) => e.Value).reduce((a: number ,b: number) => a + b);
    //       gaData.TotalLanguageUsers = data.UsersByLanguage.map((e: any) => e.Value).reduce((a: number ,b: number) => a + b);
    //       pieChart.data.labels = ["新訪客率", "舊訪客率"];
    //       pieChart.data.datasets[0].data = [data.PercentNewSessions, 100-data.PercentNewSessions];
    //       lineChart.data.labels = data.UsersByDate.map((e: any) => { return e.Name });
    //       lineChart.data.datasets[0].data = data.UsersByDate.map((e: any)=> { return e.Value });
    //     }).catch((error) => CloudFun.send('error', { subject: 'GA資料取得失敗', content: error.message })
    //     ).finally(() => {
    //       load.isLoading = false
    //     });
    //   }
    // })

    return {
      gaData,
      pieChart,
      lineChart,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      load,
      messageList,
      auth,
      news,
      modalVisible,
      newsId,
      sliders,
      getTime
    };
  },
  methods: {
    showNews(id: number) {
      this.newsId = id;
      this.modalVisible = true;
    }
  }
});
