<template>
  <vxe-modal
    title-align="right"
    v-model="visible"
    :title="data.title"
    resize
    remember
    transfer
    :showHeader="true"
    show-footer
    width="80%"
    height="80%"
    @close="close"
    @hide="close"
  >
    <!-- <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">最新快訊</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a
          href="announcement"
          class="button text-white bg-theme-1 shadow-md mr-2 inline-flex items-center"
        >
          <CornerUpLeftIcon class="mr-2 w-4 h-4" />
          返回
        </a>
      </div>
    </div> -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box h-full p-5">
      <!-- <div class="shareWrap flex justify-end items-center mb-5">
        <button class=" shareButton mr-2">
          <PrinterIcon class="w-6 h-6" />
        </button>
        <button class=" shareButton mr-2">
          <Share2Icon class="w-6 h-6" />
        </button>
        <button class=" shareButton mr-2">
          <FacebookIcon class="w-6 h-6" />
        </button>
      </div> -->

      <div class="flex justify-between items-center mb-5">
        <div class="">
          <a
            class="message-class py-1 px-5 bg-theme-11 text-lg text-gray-900 text-center mr-3 rounded-3xl w-1/5"
            href=""
            >{{ data.type }}</a
          >
          <span class=" w-full text-lg text-gray-600">{{ data.date }}</span>
        </div>
        <div class="shareWrap flex justify-end items-center">
          <!-- <button class=" shareButton mr-2">
            <PrinterIcon class="w-6 h-6" />
          </button>
          <button class=" shareButton mr-2">
            <Share2Icon class="w-6 h-6" />
          </button>
          <button class=" shareButton mr-2">
            <FacebookIcon class="w-6 h-6" />
          </button> -->
        </div>
      </div>
      <div class="text-xl font-bold mb-10">
        {{ data?.title }}
      </div>
      <div class="wysiwyg" v-html="data.content"></div>
    </div>
    <!-- END: HTML Table Data -->
  </vxe-modal>
</template>

<script lang="ts">
import CloudFun, { computed, defineComponent, ref } from "@cloudfun/core";
import { toRef, watch } from "vue-demi";

export default defineComponent({
  components: {
    // Grid,
    // CheckBoxList
  },
  props: {
    modalVisible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number
    }
  },
  setup(props, { emit }) {
    // const visible = toRef(props, "modalVisible");
    const data = ref({} as any);
    const model = CloudFun.current?.model;

    const loadNews = async (id: number) => {
      data.value = await model?.dispatch("news/find", id);
      console.log(
        "🚀 ~ file: Main.vue ~ line 84 ~ loadNews ~ value",
        data.value
      );
    };

    const visible = computed<boolean>({
      get() {
        return props.modalVisible;
      },
      set(visible) {
        emit("update:modalVisible", visible);
      }
    });

    watch(
      () => props.id,
      current => {
        console.log("🚀 ~ file: Main.vue ~ line 90 ~ setup ~ current", current);

        if (current) loadNews(current);
      }
    );

    return {
      visible,
      data
    };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    }
  }
});
</script>
